import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import { DeliveryAddressPayload } from '../../store/models/basket';
import { AddressSearch } from '../AddressSearch/AddressSearch';
import { store } from '../../store';
import { CustomerContactAddress } from '../../store/models/customer-contacts';
import { Modal } from '../../lib-ns/Modal';
import { BasketState } from '../../store/models/basket.model';
import { Price } from '../Price';
import { config } from '../../../../config';
import { UserAddressesBoxes } from './UserAddressesBoxes';
import { ButtonV2 } from '../ui/ButtonV2';
import { Header } from '../ui/header';

export interface PickerAddressDialogProps {
  visible: boolean;
  allowAddressesWithoutDeliveryCost?: boolean;
  onClose: (address?: DeliveryAddressPayload) => void;
  basketState: BasketState;
}

export const PickerAddressDialog: React.FC<PickerAddressDialogProps> = (props) => {
  const { t } = useTranslation();
  const [submittingDirectPickup, toggleSubmittingDirectPickup] = useToggle(false);
  const [submittingAddress, toggleSubmittingsAddress] = useToggle(false);
  const addresses: CustomerContactAddress[] = useSelector(
    (state) => state.customerContacts?.orderAddresses,
  );
  const [loadingContacts, toggleLoadingContacts] = useToggle(true);

  const address = props.basketState?.deliveryAddress;
  const restaurant = props.basketState?.restaurants?.[0];

  useEffect(() => {
    store.dispatch.customerContacts.getData().finally(() => toggleLoadingContacts(false));
  }, []);

  const setDirectPickup = (value: boolean) => {
    toggleSubmittingDirectPickup(true);
    store.dispatch.basket
      .directPickUp({ directPickUp: value })
      .then((data) => {
        toggleSubmittingDirectPickup(false);
      })
      .catch((err) => {
        toggleSubmittingDirectPickup(false);
      });
  };

  return (
    <Modal
      open={props.visible}
      onClose={() => props.onClose()}
      containerCSSClasses={'md:w-128 lg:w-[700px]'}
    >
      <div className="p-4">
        {!props.basketState.withDirectPickUp && (
          <div>
            <Header variant={'h2'} cssClassess="mb-4">
              {t('Zmień adres dostawy')}
            </Header>
            <AddressSearch
              onPick={(address) => {
                props.onClose(address);
              }}
            />
          </div>
        )}

        <div className="space-y-4 pt-2">
          {props.basketState.withDirectPickUp && (
            <>
              <Header variant={'h3'}>
                <i className="fal fa-fork-knife mr-2 text-2xl" />
                {t('Odbiór z restauracji')}
              </Header>
              {restaurant && (
                <div className="flex flex-col rounded bg-gray-100 p-4 text-sm">
                  <div className="">
                    {restaurant.address.addressData}, {restaurant.address.addressPostalCode}{' '}
                    {restaurant.address.addressCity}
                  </div>
                  <div className="">
                    {t('Koszt dostawy')}: <Price amount={0} />
                  </div>
                </div>
              )}
            </>
          )}

          {!props.basketState.withDirectPickUp && (
            <>
              <Header variant={'h3'}>
                <i className="fal fa-home mr-2 text-2xl" />
                {t('Adres dostawy')}
              </Header>
              <div className="">
                {address ? (
                  <>
                    <div className="flex flex-col rounded bg-gray-100 p-4 text-sm">
                      <div className="">
                        {address.street} {address.details}, {address.postalCode} {address.city}
                      </div>
                      <div className="">
                        {t('Koszt dostawy')}: <Price amount={address.deliveryCost} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>{t('Nie podano')}</div>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <div className="space-y-4 pt-4">
          <Header variant={'h3'}>{t('Twoje adresy dostaw')}</Header>

          {config().isLoggedIn ? (
            <>
              {addresses && addresses.length === 0 && (
                <p className="text-sm text-gray-800">
                  {t('Nie posiadasz żadnych zapisanych adresów')}
                </p>
              )}
            </>
          ) : (
            <>
              <div className="flex flex-col rounded bg-gray-100 p-4 text-sm">
                {t('Zaloguj się i zdefiniuj swoje adresy dostaw lub wybierz adres dostawy')}
              </div>
            </>
          )}

          <UserAddressesBoxes
            allowAddressesWithoutDeliveryCost={props.allowAddressesWithoutDeliveryCost ?? false}
            loading={loadingContacts}
            onPick={(value) => {
              toggleSubmittingsAddress(true);
              store.dispatch.basket
                .directPickUp({
                  directPickUp: false,
                  dontUpdateBasketState: true,
                })
                .then((data) => {
                  toggleSubmittingsAddress(false);

                  props.onClose(value);
                })
                .catch((err) => {
                  toggleSubmittingsAddress(false);
                });
            }}
          />

          {config().isLoggedIn && (
            <ButtonV2
              variant={'primary'}
              onClick={() => (window.location.href = '/szczegoly-uzytkownika')}
            >
              {t('Zarządzaj adresami dostaw')}
            </ButtonV2>
          )}
          {!config().isLoggedIn && (
            <ButtonV2
              variant={'primary'}
              onClick={() => (window.location.href = '/accounts/login')}
            >
              {t('Zaloguj się')}
            </ButtonV2>
          )}

          {props.basketState.withDirectPickUp && (
            <ButtonV2
              variant={'primary'}
              loading={submittingAddress}
              disabled={submittingAddress || submittingDirectPickup}
              onClick={() => {
                setDirectPickup(false);
              }}
              cssClassess="float-right"
            >
              {t('Dostawa na adres')}
            </ButtonV2>
          )}
          {!props.basketState.withDirectPickUp && (
            <ButtonV2
              variant={'primary'}
              loading={submittingDirectPickup}
              disabled={submittingAddress || submittingDirectPickup}
              onClick={() => {
                setDirectPickup(true);
              }}
              cssClassess="float-right"
            >
              {t('Odbiór osobisty')}
            </ButtonV2>
          )}
        </div>
      </div>
    </Modal>
  );
};
