import React, { ReactNode } from 'react';

export interface RadioProps {
  label: string | ReactNode;
  checked: boolean;
  name?: string;
  value?: any;
  onClick: () => void;
}

export const Radio: React.FC<RadioProps> = (props) => {
  return (
    <div>
      <div
        className={`checkbox cursor-pointer ${props.checked ? 'checked' : ''}`}
        data-name={props.name}
        data-value={props.value}
        onClick={() => props.onClick()}
      >
        <svg
          className="check"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="bxs-check-circle"
            d="M15,3A12,12,0,1,0,27,15,12.014,12.014,0,0,0,15,3ZM12.6,20.3,8.146,15.85l1.694-1.7L12.6,16.9l6.353-6.353,1.7,1.7L12.6,20.3Z"
            transform="translate(-3 -3)"
            fill="#4caf50"
          />
        </svg>
        <svg
          className="circle"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g id="Ellipse_86" data-name="Ellipse 86" fill="#fff" stroke="#e0e0e0" stroke-width="2">
            <circle cx="12" cy="12" r="12" stroke="none" />
            <circle cx="12" cy="12" r="11" fill="none" />
          </g>
        </svg>
        <span className="ml-1">{props.label}</span>
      </div>
    </div>
  );
};
