import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { useToggle } from 'react-use';

import { HomeUserAddressesDialog } from './components/HomeUserAddressesDialog'
import { OrderDirectPickDialog } from './components/order/OrderDirectPickupDialog';
import { config } from '../../config';
import { EventsActions } from '../common/Events';
import { $ } from '../common/helpers';
import { store } from './store';
import { I18N_RESOURCES } from './utils/i18n-resources';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: I18N_RESOURCES,
    lng: config().languageCode,
    fallbackLng: 'pl',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

const App: React.FC = (props) => {
  const [directPickupDialogVisible, toggleDirectPickupDialogVisible] = useToggle(false);

  // address change
  const [addressPickerVisible, toggleAddressPicker] = useToggle(false);
  const [submitting, toggleSubmitting] = useToggle(false);

  const setDeliveryPickup = () => {
    store.dispatch.basket
      .directPickUp({ directPickUp: false })
      .then((data) => {
        $('#delivery-radio-delivery').classList.add('checked');
        $('#delivery-radio-pickup').classList.remove('checked');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    window['ev'].on(EventsActions.HOME_SHOW_DIRECT_PICKUP_DIALOG, () => {
      toggleDirectPickupDialogVisible(true);
    });
    window['ev'].on(EventsActions.HOME_SET_PICKUP_TO_DELIVERY, () => {
      setDeliveryPickup();
    });

    return () => {
      window['ev'].off(EventsActions.HOME_SHOW_DIRECT_PICKUP_DIALOG, true);
      window['ev'].off(EventsActions.HOME_SET_PICKUP_TO_DELIVERY, true);
    };
  }, []);

  return (
    <Provider store={store}>
      <div className="App">
        {props.children}

        <OrderDirectPickDialog
          visible={directPickupDialogVisible}
          onClose={(value) => {
            if (value) {
              $('#delivery-radio-pickup').classList.add('checked');
              $('#delivery-radio-delivery').classList.remove('checked');
            } else {
              $('#delivery-radio-delivery').classList.add('checked');
              $('#delivery-radio-pickup').classList.remove('checked');
            }

            toggleDirectPickupDialogVisible(false);
          }}
        />
        <HomeUserAddressesDialog />
      </div>
    </Provider>
  );
};

const container = document.querySelector('#homeApp');
ReactDOM.render(<App></App>, container);
