import React, { FunctionComponent, ReactNode } from 'react';
import { pipe } from 'rambdax';

/**
 Example of priceWithCurrency
 {
    "amount": 45000,
    "currency": {
      "symbol": "€",
      "rounding": 0,
      "name": "Euro",
      "iso": "EUR",
      "decimalDigits": 2,
      "decimalSeparator": ",",
      "spaceBetweenAmountAndSymbol": true,
      "symbolOnLeft": false,
      "thousandsSeparator": " ",
    },
  }
 */

export interface Currency {
  symbol: string;
  name: string;
  iso: string;
  rounding: number;
  decimalDigits: number;
  decimalSeparator: string;
  thousandsSeparator: string;
  spaceBetweenAmountAndSymbol: boolean;
  prefixWithSymbol: boolean;
}

export interface PriceProps {
  children?: ReactNode;
  amount: number | null; // price should be store as whole numbers in database
  currency?: Currency;
  old?: boolean;
}

const PriceDefaultProps = {
  currency: {
    symbol: 'zł',
    name: 'PLN',
    iso: 'PLN',
    rounding: 100,
    decimalDigits: 2,
    decimalSeparator: ',',
    thousandsSeparator: ' ',
    spaceBetweenAmountAndSymbol: true,
    prefixWithSymbol: false,
  },
};
// const PriceContainer = styled.span`
//   white-space: nowrap;
//   ${({ old }) =>
//     old &&
//     `text-decoration: line-through;
//     color: #cf1e2f;`}
// `;

export const formattedPrice: (options: {
  currency?: Currency;
  amount: number;
  old?: boolean;
}) => string = ({ currency, amount, old }) => {
  if (amount === null || amount === undefined) {
    return '- zł';
  }
  const _currency = currency || PriceDefaultProps.currency;

  return pipe(
    (_amount: number) => _amount / _currency.rounding,
    (roundedAmount) => {
      return roundedAmount.toFixed(_currency.decimalDigits);
    },
    (amountWithDecimals) => {
      return amountWithDecimals.replace('.', _currency.decimalSeparator);
    },
    (amountWithDecimals) => {
      return `${_currency.prefixWithSymbol ? _currency.symbol : ''}${amountWithDecimals}${
        !_currency.prefixWithSymbol ? _currency.symbol : ''
      }`;
    },
    (priceWithSymbol) => {
      return _currency.spaceBetweenAmountAndSymbol
        ? priceWithSymbol.replace(
            _currency.symbol,
            `${!_currency.prefixWithSymbol ? ' ' : ''}${_currency.symbol}${
              _currency.prefixWithSymbol ? ' ' : ''
            }`,
          )
        : priceWithSymbol;
    },
  )(amount);
};

// todo - finish formatting according to all options
const Price: FunctionComponent<PriceProps> = ({ currency, amount, old }) => {
  if (!currency || amount === null) {
    return null;
  }

  return <span>{formattedPrice({ currency, amount })}</span>;
};

Price.defaultProps = PriceDefaultProps;

export { Price };
