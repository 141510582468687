import React from 'react';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  containerCSSClasses?: string
}

export const Modal: React.FC<ModalProps> = (props) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 max-h-[90vh] overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={(isOpened) => props.onClose()}
      >
        <div className="flex min-h-screen items-center justify-center overflow-hidden rounded-md px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`ModalContainer inline-block w-full transform rounded-md bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:align-middle p-4 ${props.containerCSSClasses ? props.containerCSSClasses : 'md:w-128 lg:w-140'}`}>
              <div className="absolute -top-3 -right-3 hidden sm:block">
                <button
                  type="button"
                  className="focus:outline-none rounded-full border border-red bg-white p-1 text-gray-400 hover:text-gray-500"
                  onClick={() => props.onClose()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-4 w-4 text-red" aria-hidden="true" />
                </button>
              </div>

              {props.children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
