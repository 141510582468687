import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconFa, IconSize } from '../..';
import { styled } from '../../../../utils/styled-components';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

export interface LoadingProps {
  children?: ReactNode;
  text?: string;
}

const LoadingDefaultProps = {
  text: '',
};

const Loading: FunctionComponent<LoadingProps> = (props: LoadingProps) => {
  const { t } = useTranslation();
  return (
    <LoadingContainer>
      <Icon name="spinner" fa={IconFa.Fal} spin size={IconSize.LargeX} />
      <span className={'ml-2'}>{props.text || t('Trwa ładowanie')}</span>
    </LoadingContainer>
  );
};

Loading.defaultProps = LoadingDefaultProps;

export { Loading };
