import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { store } from '../../store';
import { ButtonV2 } from '../ui/ButtonV2';
import { Radio } from '../ui/radio';
import { Modal } from '../../lib-ns/Modal';

export interface MealDialogProps {
  onClose: (value: boolean) => void;
  visible: boolean;
}

export const OrderDirectPickDialog: React.FC<MealDialogProps> = (props) => {
  const { t } = useTranslation();
  const [submitting, toggleSubmitting] = useToggle(false);
  const [clickedAction, setClickedAction] = useState<null | 'direct' | 'delivery'>(null)

  const setDirectPickup = (value: boolean) => {
    toggleSubmitting(true);
    setClickedAction(value ? 'direct' : 'delivery')
    store.dispatch.basket
      .directPickUp({ directPickUp: value })
      .then((data) => {
        toggleSubmitting(false);
        props.onClose(value);
      })
      .catch((err) => {
        toggleSubmitting(false);
        console.error(err);
      });
  };

  return (
    <Modal open={props.visible} onClose={() => props.onClose(false)}>
      <div className="space-y-6 px-6 py-8">
        <div className="flex flex-col items-center justify-center space-y-2">
          <div className="">
            <i className="fa-light fa-head-side-headphones text-4xl"></i>
          </div>
          <p className={'font-medium'}>Czy chcesz samodzielnie odebrać zamówienie?</p>
        </div>

        <div className="flex items-center justify-between space-x-6">
          <ButtonV2
            loading={submitting && clickedAction === 'direct'}
            disabled={submitting && clickedAction === 'delivery'}
            variant={'primary'}
            cssClassess={'w-full'}
            onClick={() => {
              setDirectPickup(true);
            }}
          >
            <span>{t('TAK')}</span>
          </ButtonV2>
          <ButtonV2
            loading={submitting && clickedAction === 'delivery'}
            disabled={submitting && clickedAction === 'direct'}
            variant={'secondary'}
            cssClassess={'button-secondary w-full'}
            onClick={() => {
              setDirectPickup(false);
            }}
          >
            <span>{t('NIE')}</span>
          </ButtonV2>
        </div>

        <div className={'space-y-4'}>
          <Radio checked={true} label={'Nie ponosisz kosztów dostawy'} />
          <Radio checked={true} label={'Otrzymujesz 1 monetę za każde 10 zł wartości zamówienia'} />
        </div>
      </div>
    </Modal>
  );
};
