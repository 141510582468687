export const I18N_RESOURCES = {
  en: {
    translation: {
      'Wybierz dodatki': 'Dodatki. (Wybierz {{ howMany }} z {{ from }})',
      'Wybierz dodatki - error': 'Wybierz {{ howMany }} z {{ from }}',
      'saved points': 'Właśnie zaoszczędziłeś {{ points }} zł na swoim zamówieniu.',
      'Wystąpił nieoczekiwany błąd': 'Wystąpił nieoczekiwany błąd',
      Szukaj: 'Szukaj',
      'Brak wyników dla podanego adresu.': 'Brak wyników dla podanego adresu.',
      Zmień: 'Zmień',
      'Twoje zamówienie': 'Your order',
      'Nazwa restauracji': 'Nazwa restauracji',
      'Koszyk jest pusty': 'Basket is empty',
      Zamawiam: 'Zamawiam',
      Informacja: 'Information',
      Wyczyść: 'Clear',
      Posiłki: 'Meals',
      Dostawa: 'Delivery',
      Rabat: 'Discount',
      Razem: 'Sum',
      Nadpłata: 'Balance +',
      Niedopłata: 'Balance -',
      'Napiwek dla kierowcy': 'Napiwek dla kierowcy',
      Monety: 'Monety',
      'Do zapłaty': 'Payment amount',
      Dodatki: 'Dodatki',
      'Uwagi do zamówienia...': 'Uwagi do zamówienia...',
      'Dodaj do zamówienia': 'Dodaj do zamówienia',
      'Ostrość - średnia': 'Ostrość - średnia',
      'Ostrość - ostra': 'Ostrość - ostra',
      'Ostrość - extra ostra': 'Ostrość - extra ostra',
      Nowość: 'Nowość',
      Lunch: 'Lunch',
      Bezglutenowe: 'Bezglutenowe',
      Napój: 'Napój',
      Wegańskie: 'Wegańskie',
      Wegetariańskie: 'Wegetariańskie',
      Alkohol: 'Alkohol',
      Odbiorca: 'Odbiorca',
      Faktura: 'Faktura',
      'Uwagi do zamówienia': 'Uwagi do zamówienia',
      'Złożenie zamówienia': 'Złożenie zamówienia',
      Typ: 'Typ',
      Restauracja: 'Restauracja',
      'Odbiór klient': 'Odbiór klient',
      'Jak najszybciej': 'Jak najszybciej',
      'Błąd serwera. Spróbuj złożyć zamówienie telefonicznie.':
        'Błąd serwera. Spróbuj złożyć zamówienie telefonicznie.',
      'Termin dostawy': 'Termin dostawy',
      'Telefon kontaktowy': 'Telefon kontaktowy',
      'E-mail': 'E-mail',
      'Chcesz fakturę VAT?': 'Chcesz fakturę VAT?',
      'Zapoznałem się z': 'Zapoznałem się z',
      regulaminem: 'regulaminem',
      'Zamawiam i płacę': 'Zamawiam i płacę',
      monety: 'monety',
      'Edytuj adres': 'Edytuj adres',
      'Dodaj adres': 'Dodaj adres',
      Adres: 'Adres',
      'Kod pocztowy': 'Kod pocztowy',
      Miasto: 'Miasto',
      'Ustaw jako główny': 'Ustaw jako główny',
      Uwagi: 'Uwagi',
      Zapisz: 'Zapisz',
      Anuluj: 'Anuluj',
      'Edytuj firmę': 'Edytuj firmę',
      'Dodaj firmę': 'Dodaj firmę',
      'Pole jest wymagane': 'Pole jest wymagane',
      'Podane hasła nie są identyczne': 'Podane hasła nie są identyczne',
      'Zmiana hasła': 'Zmiana hasła',
      'Stare hasło': 'Stare hasło',
      'Nowe hasło': 'Nowe hasło',
      'Powtórz nowe hasło': 'Powtórz nowe hasło',
      'Edytuj telefon': 'Edytuj telefon',
      'Dodaj telefon': 'Dodaj telefon',
      'Lp.': 'Lp.',
      'Dodatkowe informacje': 'Dodatkowe informacje',
      Wybierz: 'Wybierz',
      'Czy na pewno chcesz usunąć ten adres?': 'Czy na pewno chcesz usunąć ten adres?',
      Tak: 'Tak',
      Nie: 'Nie',
      'Dodaj nowy adres': 'Dodaj nowy adres',
      'Twoje dane': 'Twoje dane',
      'Imię i nazwisko': 'Imię i nazwisko',
      'ost. logowanie': 'ost. logowanie',
      'Zmień dane': 'Zmień dane',
      Saldo: 'Saldo',
      'Warunki programu lojanościowego': 'Warunki programu lojanościowego',
      'Błąd pobierania danych': 'Błąd pobierania danych',
      'Firmy do faktur': 'Firmy do faktur',
      Firma: 'Firma',
      'E-mail do faktury': 'E-mail do faktury',
      NIP: 'NIP',
      'Czy na pewno chcesz usunąć tą firmę?': 'Czy na pewno chcesz usunąć tą firmę?',
      'Dodaj nową firmę': 'Dodaj nową firmę',
      'Zmiana danych': 'Zmiana danych',
      'Adres e-mail': 'Adres e-mail',
      'Nazwa użytkownika': 'Nazwa użytkownika',
      Wyloguj: 'Wyloguj',
      'Adresy dostaw': 'Adresy dostaw',
      'Twoje zamówienia': 'Twoje zamówienia',
      'Data odebrania': 'Data odebrania',
      Kwota: 'Kwota',
      'Telefony do kontaktu': 'Telefony do kontaktu',
      'Dodaj nowy': 'Dodaj nowy',
      'Edytuj dane do faktury': 'Edytuj dane do faktury',
      'Nazwa firmy': 'Nazwa firmy',
      'Numer NIP': 'Numer NIP',
      'Adres odbioru': 'Adres odbioru',
      'Adres dostawy': 'Adres dostawy',
      'Ustaw adres dostawy lub wybierz opcję odbioru osobistego':
        'Ustaw adres dostawy lub wybierz opcję odbioru osobistego',
      'Dostawa na adres': 'Dostawa na adres',
      'Odbiór osobisty': 'Odbiór osobisty',
      'YYYY-MM-DD HH:mm': 'YYYY-MM-DD HH:mm',
      'Najszybciej jak się da': 'Najszybciej jak się da',
      'Preferowany czas dostawy': 'Preferowany czas dostawy',
      Ustaw: 'Ustaw',
      'Uzupełnij dane do faktury klikając przycisk zmień':
        'Uzupełnij dane do faktury klikając przycisk zmień',
      'Twoje firmy': 'Twoje firmy',
      'Preferowana forma płatności': 'Preferowana forma płatności',
      'Twoje telefony': 'Twoje telefony',
      'Z tym zamówieniem zyskujesz': 'Z tym zamówieniem zyskujesz',
      'Zrabatuj koszyk o moje monety': 'Zrabatuj koszyk o moje monety',
      'Przywróć monety': 'Przywróć monety',
      'Użyj monet': 'Użyj monet',
      'Gratulacje!': 'Gratulacje!',
      'Dziękujemy, że jesteś z nami.': 'Dziękujemy, że jesteś z nami.',
      'Z tym zamówieniem zyskujesz dodatkowe monety':
        'Z tym zamówieniem zyskujesz dodatkowe monety',
      Niezalogowany: 'Niezalogowany',
      'Zaloguj się i zbieraj monety': 'Zaloguj się i zbieraj monety',
      'programu lojalnościowego': 'programu lojalnościowego',
      'Twoje Adresy': 'Twoje Adresy',
      'Nie posiadasz żadnych zapisanych adresów': 'Nie posiadasz żadnych zapisanych adresów',
      'Inny adres dostawy': 'Inny adres dostawy',
      ',': ',',
      'Wyszukaj potrawę': 'Wyszukaj potrawę',
      click: 'click',
      'Trwa ładowanie': 'Trwa ładowanie',
      '.meal': '.meal',
      '.meal__header-likes': '.meal__header-likes',
    },
  },
  pl: {
    translation: {
      'Wystąpił nieoczekiwany błąd': 'Wystąpił nieoczekiwany błąd',
      Szukaj: 'Szukaj',
      'Brak wyników dla podanego adresu.': 'Brak wyników dla podanego adresu.',
      Zmień: 'Zmień',
      'Twoje zamówienie': 'Twoje zamówienie',
      'Nazwa restauracji': 'Nazwa restauracji',
      'Koszyk jest pusty': 'Koszyk jest pusty',
      Zamawiam: 'Zamawiam',
      Informacja: 'Informacja',
      Wyczyść: 'Wyczyść',
      Posiłki: 'Posiłki',
      Dostawa: 'Dostawa',
      Rabat: 'Rabat',
      Razem: 'Razem',
      Nadpłata: 'Nadpłata',
      Niedopłata: 'Niedopłata',
      'Napiwek dla kierowcy': 'Napiwek dla kierowcy :)',
      Monety: 'Monety',
      'Do zapłaty': 'Do zapłaty',
      Dodatki: 'Dodatki',
      'Uwagi do zamówienia...': 'Uwagi do zamówienia...',
      'Dodaj do zamówienia': 'Dodaj do zamówienia',
      'Ostrość - średnia': 'Ostrość - średnia',
      'Ostrość - ostra': 'Ostrość - ostra',
      'Ostrość - extra ostra': 'Ostrość - extra ostra',
      Nowość: 'Nowość',
      Lunch: 'Lunch',
      Bezglutenowe: 'Bezglutenowe',
      Napój: 'Napój',
      Wegańskie: 'Wegańskie',
      Wegetariańskie: 'Wegetariańskie',
      Alkohol: 'Alkohol',
      Odbiorca: 'Odbiorca',
      Faktura: 'Faktura',
      'Uwagi do zamówienia': 'Uwagi do zamówienia',
      'Złożenie zamówienia': 'Złożenie zamówienia',
      Typ: 'Typ',
      Restauracja: 'Restauracja',
      'Odbiór klient': 'Odbiór klient',
      'Jak najszybciej': 'Jak najszybciej',
      'Błąd serwera. Spróbuj złożyć zamówienie telefonicznie.':
        'Błąd serwera. Spróbuj złożyć zamówienie telefonicznie.',
      'Termin dostawy': 'Termin dostawy',
      'Telefon kontaktowy': 'Telefon kontaktowy',
      'E-mail': 'E-mail',
      'Chcesz fakturę VAT?': 'Chcesz fakturę VAT?',
      'Zapoznałem się z': 'Zapoznałem się z',
      regulaminem: 'regulaminem',
      'Zamawiam i płacę': 'Zamawiam i płacę',
      monety: 'monety',
      'Edytuj adres': 'Edytuj adres',
      'Dodaj adres': 'Dodaj adres',
      Adres: 'Adres',
      'Kod pocztowy': 'Kod pocztowy',
      Miasto: 'Miasto',
      'Ustaw jako główny': 'Ustaw jako główny',
      Uwagi: 'Uwagi',
      Zapisz: 'Zapisz',
      Anuluj: 'Anuluj',
      'Edytuj firmę': 'Edytuj firmę',
      'Dodaj firmę': 'Dodaj firmę',
      'Pole jest wymagane': 'Pole jest wymagane',
      'Podane hasła nie są identyczne': 'Podane hasła nie są identyczne',
      'Zmiana hasła': 'Zmiana hasła',
      'Stare hasło': 'Stare hasło',
      'Nowe hasło': 'Nowe hasło',
      'Powtórz nowe hasło': 'Powtórz nowe hasło',
      'Edytuj telefon': 'Edytuj telefon',
      'Dodaj telefon': 'Dodaj telefon',
      'Lp.': 'Lp.',
      'Dodatkowe informacje': 'Dodatkowe informacje',
      Wybierz: 'Wybierz',
      'Czy na pewno chcesz usunąć ten adres?': 'Czy na pewno chcesz usunąć ten adres?',
      Tak: 'Tak',
      Nie: 'Nie',
      'Dodaj nowy adres': 'Dodaj nowy adres',
      'Twoje dane': 'Twoje dane',
      'Imię i nazwisko': 'Imię i nazwisko',
      'ost. logowanie': 'ost. logowanie',
      'Zmień dane': 'Zmień dane',
      Saldo: 'Saldo',
      'Warunki programu lojanościowego': 'Warunki programu lojanościowego',
      'Błąd pobierania danych': 'Błąd pobierania danych',
      'Firmy do faktur': 'Firmy do faktur',
      Firma: 'Firma',
      'E-mail do faktury': 'E-mail do faktury',
      NIP: 'NIP',
      'Czy na pewno chcesz usunąć tą firmę?': 'Czy na pewno chcesz usunąć tą firmę?',
      'Dodaj nową firmę': 'Dodaj nową firmę',
      'Zmiana danych': 'Zmiana danych',
      'Adres e-mail': 'Adres e-mail',
      'Nazwa użytkownika': 'Nazwa użytkownika',
      Wyloguj: 'Wyloguj',
      'Adresy dostaw': 'Adresy dostaw',
      'Twoje zamówienia': 'Twoje zamówienia',
      'Data odebrania': 'Data odebrania',
      Kwota: 'Kwota',
      'Telefony do kontaktu': 'Telefony do kontaktu',
      'Dodaj nowy': 'Dodaj nowy',
      'Edytuj dane do faktury': 'Edytuj dane do faktury',
      'Nazwa firmy': 'Nazwa firmy',
      'Numer NIP': 'Numer NIP',
      'Adres odbioru': 'Adres odbioru',
      'Adres dostawy': 'Adres dostawy',
      'Ustaw adres dostawy lub wybierz opcję odbioru osobistego':
        'Ustaw adres dostawy lub wybierz opcję odbioru osobistego',
      'Dostawa na adres': 'Dostawa na adres',
      'Odbiór osobisty': 'Odbiór osobisty',
      'YYYY-MM-DD HH:mm': 'YYYY-MM-DD HH:mm',
      'Najszybciej jak się da': 'Najszybciej jak się da',
      'Preferowany czas dostawy': 'Preferowany czas dostawy',
      Ustaw: 'Ustaw',
      'Uzupełnij dane do faktury klikając przycisk zmień':
        'Uzupełnij dane do faktury klikając przycisk zmień',
      'Twoje firmy': 'Twoje firmy',
      'Preferowana forma płatności': 'Preferowana forma płatności',
      'Twoje telefony': 'Twoje telefony',
      'Z tym zamówieniem zyskujesz': 'Z tym zamówieniem zyskujesz',
      'Zrabatuj koszyk o moje monety': 'Zrabatuj koszyk o moje monety',
      'Przywróć monety': 'Przywróć monety',
      'Użyj monet': 'Użyj monet',
      'Gratulacje!': 'Gratulacje!',
      'Dziękujemy, że jesteś z nami.': 'Dziękujemy, że jesteś z nami.',
      'Z tym zamówieniem zyskujesz dodatkowe monety':
        'Z tym zamówieniem zyskujesz dodatkowe monety',
      Niezalogowany: 'Niezalogowany',
      'Zaloguj się i zbieraj monety': 'Zaloguj się i zbieraj monety',
      'programu lojalnościowego': 'programu lojalnościowego',
      'Twoje Adresy': 'Twoje Adresy',
      'Nie posiadasz żadnych zapisanych adresów': 'Nie posiadasz żadnych zapisanych adresów',
      'Inny adres dostawy': 'Inny adres dostawy',
      ',': ',',
      'Wyszukaj potrawę': 'Wyszukaj potrawę',
      click: 'click',
      'Trwa ładowanie': 'Trwa ładowanie',
      '.meal': '.meal',
      '.meal__header-likes': '.meal__header-likes',
    },
  },
};
