import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { useDebounce } from '../../lib-ns/hooks/use-debounce';
import { DeliveryAddressPayload } from '../../store/models/basket';
import { Loading } from '../../lib/ui';
import { HttpApi } from '../../../common/https';
import { ErrorText } from '../ui/ErrorText';
import { ButtonV2 } from '../ui/ButtonV2';

export interface SearchAddressResult {
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  lon: string;
  lat: string;
}

export interface AddressSearchProps {
  onPick?: (item: DeliveryAddressPayload) => void;
}

export const AddressSearch: React.FC<AddressSearchProps> = (props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 250);
  const [error, setError] = useState<string>('');
  const [loading, toggleLoading] = useToggle(false);
  const [usedSearch, toggleUsedSearch] = useToggle(false);

  const [results, setResults] = useState<SearchAddressResult[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const searchAddressRequest = (queryValue) => {
    if (loading) {
      return;
    }

    toggleLoading(true);
    toggleUsedSearch(true);
    setResults([]);
    HttpApi.post('api/v1/searchAddress/', {
      address: queryValue,
    })
      .then((response) => {
        setResults(response.data.results);
        toggleLoading(false);
      })
      .catch((error) => {
        setError(error.data?.detail ?? t('Wystąpił nieoczekiwany błąd'));
        toggleLoading(false);
      });
  };

  useEffect(() => {
    if (!query) {
      toggleUsedSearch(false);
    }
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      searchAddressRequest(debouncedQuery);
    }
  }, [debouncedQuery]);

  return (
    <div className="relative">
      <div className="flex items-center">
        <div className="flex w-full items-center justify-center rounded border border-gray-200 p-2">
          <img
            src="/static/www/images/location-pin.svg"
            alt="Search icon"
            className={'hidden h-12 lg:inline-block'}
          />
          <input
            type="text"
            value={query}
            onChange={(ev) => setQuery(ev.currentTarget.value)}
            onKeyDown={(ev) => {
              if (ev.key == 'Enter') {
                searchAddressRequest(query);
              }
            }}
            className="mx-6 flex-grow py-2"
            placeholder={t('Wpisz adres dostawy...')}
            style={{ marginBottom: 0 }}
          />
          <ButtonV2
            cssClassess={'hidden lg:inline-block'}
            variant={'red'}
            onClick={() => searchAddressRequest(query)}
          >
            <img src="/static/www/images/search-icon.svg" alt="Search icon" />
          </ButtonV2>
        </div>
      </div>

      {loading && <Loading />}

      {!loading && error && (
        <div className="mb-4">
          <ErrorText>{error}</ErrorText>
        </div>
      )}

      {usedSearch && !loading && !error && results.length === 0 && (
        <div className="mb-4">{t('Brak wyników dla podanego adresu.')}</div>
      )}

      <ul className="divide-y divide-gray-200">
        {results.length > 0 &&
          results.map((address) => (
            <li
              className="focus:outline-none group m-0 block cursor-pointer transition duration-150 ease-in-out hover:bg-gray-100"
              onClick={() => {
                if (props.onPick) {
                  props.onPick(address);
                }
              }}
            >
              <div className="flex items-center">
                <div className="w-10 text-center">
                  <span className="group-hover:hidden">
                    <i className="fad fa-map-marker" />
                  </span>
                  <span className="hidden group-hover:inline">
                    <i className="fas fa-map-marker" />
                  </span>
                </div>
                <div className="my-2 flex flex-col px-4 py-4">
                  <div className="truncate font-bold leading-5">
                    {address.street} {address.houseNumber}
                  </div>
                  <div className="truncate font-normal ">
                    {address.postalCode} {address.city}
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};
