import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CustomerContactAddress } from '../../store/models/customer-contacts';
import { Price } from '../Price';

export interface UserAddressesProps {
  loading: boolean;
  allowAddressesWithoutDeliveryCost?: boolean;
  onPick?: (item: CustomerContactAddress) => void;
}

export const UserAddressesBoxes: React.FC<UserAddressesProps> = (props) => {
  const { t } = useTranslation();

  const items: CustomerContactAddress[] = useSelector(
    (state) => state.customerContacts?.orderAddresses ?? [],
  );


  return (
    <div className="my-2">
      {(!props.loading || (items && items.length > 0)) && (
        <div className={'grid grid-cols-1 gap-4 lg:grid-cols-3'}>
          {items.map((item, index) => {
            const deliveryCost = item.deliveryCost ?? 0;
            const isAddressAllowedToPick = (deliveryCost > 0 || props.allowAddressesWithoutDeliveryCost) && item.lat && item.lon
            return (
              <div
                className={`group bg-gray-100 p-4 ${
                  isAddressAllowedToPick ? 'cursor-pointer hover:bg-gray-300' : ''
                }`}
                key={item.id}
                onClick={() => {
                  if (isAddressAllowedToPick && props.onPick) {
                    props.onPick(item);
                  }
                }}
              >
                <div className="s">{item.street} {item.houseNumber ? item.houseNumber: ''}</div>
                <div className="s">{item.details ? item.details : ''}</div>
                <div className="mt-2">
                  {item.postalCode} {item.city}
                </div>
                <div className="mt-6">
                  {!isAddressAllowedToPick && (
                    <span className="text-gray-500">Brak możliwości wybrania adresu.</span>
                  )}
                  {deliveryCost > 0 && (
                    <>
                      {t('Koszt dostawy')}: <Price amount={deliveryCost} />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
