import React from 'react';

export interface BButtonV2Props {
  loading?: boolean;
  // active?: boolean;
  disabled?: boolean;
  cssClassess?: string;
  onClick?: () => void;
  variant?:
    | 'primary'
    | 'secondary'
    | 'primary-inline'
    | 'gray'
    | 'red'
    | 'red-small'
    | 'inline'
    | 'basketPlus';
}

export const ButtonV2: React.FC<BButtonV2Props> = (props) => {
  let additionalClasses = '';
  switch (props.variant) {
    case 'gray':
      additionalClasses += 'px-8 py-2 text-4xl bg-gray-200 text-green';
      break;
    case 'basketPlus':
      additionalClasses += 'px-6 py-2 text-xl bg-gray-200 text-green';
      break;
    case 'primary':
      additionalClasses += 'button-secondary bg-green';
      // "p-4 rounded bg-green font-bold text-white text-xl text-left";
      break;
    case 'secondary':
      additionalClasses +=
        'bg-gray-200 p-1 rounded text-darkGrey hover:scale-100 hover:bg-gray-300';
      // "p-4 rounded bg-green font-bold text-white text-xl text-left";
      break;
    case 'red':
      additionalClasses += 'p-4 rounded bg-red font-bold text-white text-xl text-left';
      break;
    case 'red-small':
      additionalClasses += 'px-4 py-2 rounded bg-red font-bold text-white text-xl text-left';
      break;
    case 'inline':
      additionalClasses += 'px-1 py-2 text-green text-left';
      break;
    case 'primary-inline':
      additionalClasses +=
        'py-1 px-2 text-sm text-green text-left rounded border border-green border-rounded bg-white';
      break;
    default:
      additionalClasses += 'py-2 px-1 text-center rounded';
      break;
  }

  return (
    <button
      type="button"
      className={`flex transform items-center justify-center space-x-2 transition-transform hover:scale-105 
        ${additionalClasses} 
        ${props.cssClassess ?? ''}
      `}
      onClick={() => {
        if (props.onClick && !props.loading && !props.disabled) {
          props.onClick();
        }
      }}
    >
      {props.loading && <i className="fal fa-spinner-third fa-spin" />}
      {!props.loading && <>{props.children}</>}
    </button>
  );
};
