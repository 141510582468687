import React, { useEffect } from 'react'
import { useToggle } from 'react-use'
import { useSelector, useDispatch, Provider } from 'react-redux';

import { PickerAddressDialog } from './order/PickerAddressDialog'
import { store } from '../store'
import { DeliveryAddressPayload } from '../store/models/basket'
import { BasketState } from '../store/models/basket.model'
import { EventsActions } from '../../common/Events'

export interface UserAddressesDialogProps {

}

export const HomeUserAddressesDialog: React.FC<UserAddressesDialogProps> = props => {

  const basketState: BasketState = useSelector((state) => state.basket);

  // address change
  const [addressPickerVisible, toggleAddressPicker] = useToggle(false);
  const [submitting, toggleSubmitting] = useToggle(false);

  useEffect(() => {
    window['ev'].on(EventsActions.HOME_DISPLAY_ADDRESSES_DIALOG, () => {
      toggleAddressPicker();
    });

    return () => {
      window['ev'].off(EventsActions.HOME_DISPLAY_ADDRESSES_DIALOG, true);
    };
  }, []);

  const updateAddress = (value: DeliveryAddressPayload) => {
    toggleSubmitting(true);
    store.dispatch.basket
      .updateDeliveryAddress({
        address: value,
      })
      .then((data) => {})
      .catch((err) => {})
      .finally(() => {
        toggleSubmitting(false);
        toggleAddressPicker(false);

        window.location.href = `/restauracje/`
      });
  };

  return (
    <div>

      {addressPickerVisible && (
        <PickerAddressDialog
          visible
          allowAddressesWithoutDeliveryCost
          onClose={(value) => {
            if (value) {
              updateAddress(value);
            }
            toggleAddressPicker(false);
          }}
          basketState={basketState}
        />
      )}

    </div>
  )
}
