import React from 'react';

export interface HeaderProps {
  variant: 'h2' | 'h3';
  cssClassess?: string;
}

export const Header: React.FC<HeaderProps> = (props) => {
  let c = `font-bold pt-2 `;

  switch (props.variant) {
    case 'h2':
      c += `text-3xl `;
      break;
    case 'h3':
    default:
      c += `text-xl `;
      break;
  }

  if (props.cssClassess) {
    c += ` ${props.cssClassess} `;
  }

  switch (props.variant) {
    case 'h2':
      return <h2 className={`${c}`}>{props.children}</h2>;
    case 'h3':
    default:
      return <h3 className={`${c}`}>{props.children}</h3>;
  }
};
